import './main.css';
import { Elm } from './Main.elm';

let app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    environment: NODE_ENV || 'development',
    version: APP_VERSION,
  }
});

let sendToPort = (portName, data) => {
  if (app.ports[portName]) {
    app.ports[portName].send(data)
  }
  else {
    logger.error(`Failed sending data to port: ${portName}`)
  }
}

let updateMediaQuery = () => {
  if (window.matchMedia("(min-width: 768px)").matches) {
    sendToPort("setWide", true)
  }
  else {
    sendToPort("setWide", false)
  }
}

document.addEventListener('DOMContentLoaded', updateMediaQuery)
window.addEventListener('resize', updateMediaQuery, false)
